import React from 'react';
import Select from 'react-dropdown-select';
import classnames from 'classnames';

import styles from './styles.module.scss';

const SelectInput = (props) => {
  const {
    name,
    options,
    value,
    multi,
    onChange,
    disabled,
    error,
    loading,
    borderBottom,
    className,
    style,
    hideErrorMessage,
    ...restProps
  } = props;

  const values = options.filter((option) =>
    typeof value === 'object' && value !== null
      ? value.includes(option.value)
      : option.value === value
  );

  const changeHandler = (valuesArray) => {
    if (multi && Array.isArray(valuesArray)) {
      return onChange(valuesArray.map((item) => item.value));
    }
    if (Array.isArray(valuesArray) && valuesArray.length > 0) {
      return onChange(valuesArray[0].value);
    }

    return onChange('');
  };

  return (
    <div>
      <Select
        color="#03a9f4"
        style={style}
        className={`${className} ${classnames(styles.input, {
          [styles.error]: !!error,
          [styles.borderNone]: borderBottom,
        })}`}
        onChange={changeHandler}
        {...{ name, options, values, disabled, loading, multi }}
        {...restProps}
      />
      {!hideErrorMessage && <span className={styles.errorMsg}>{error}</span>}
    </div>
  );
};

export default SelectInput;
