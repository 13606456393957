import React from 'react';

import styles from './styles.module.scss';
import useLocaleSwitcher from '../../../hooks/useLocaleSwitcher';
import LocaleSelect from '../LocaleSelect';

const LocaleSwitch = (props) => {
  const { closeNavBar, ...restProps } = props || {};
  const { currentLocale, switchLocale } = useLocaleSwitcher();

  const onChange = (value) => {
    switchLocale(value);
  };

  return (
    <LocaleSelect
      value={currentLocale}
      onChange={onChange}
      showAllLocales
      wrapperStyle={styles.selectWrappr}
      useLocaleSwitchStyles
      closeNavBar
      {...restProps}
    />
  );
};

export default LocaleSwitch;
