import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import { useModal } from '../../../hooks/modal';
import useTranslations from '../../../hooks/translations';
import styles from './styles.module.scss';
import SelectInput from '../SelectInput';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import useAuthUser from '../../../hooks/authUser';
import { getAllLocales } from '../../../models/locale';
import SelectLocaleModal from './SelectLocaleModal';
import appConstants from '../../../utils/constants/app';

const LocaleSelect = ({
  value,
  onChange,
  disabled,
  showAllLocales,
  showUserApprovedLocales,
  wrapperStyle,
  lessPadding,
  useLocaleSwitchStyles,
  closeNavBar,
  ...restProps
}) => {
  const { t } = useTranslations();
  const LOCALES = getAllLocales();
  const { coachDetails, user } = useAuthUser();
  const [options, setOptions] = useState([]);
  const [currentSelectedItem, setCurrentSelectedItem] = useState({
    value: LOCALES.en.id,
    label: t(LOCALES.en.title),
    flag: LOCALES.en.logo,
  });
  const modalRef = useRef(null);
  const { isModalOpen, toggleModal } = useModal({ modalRef });
  const isMobile = useDeviceDetect();
  const { approvedLocales: coachApprovedLocales } = coachDetails || {};
  const { approvedLocales: userApprovedLocales } = user || {};

  useEffect(() => {
    if (value)
      setCurrentSelectedItem({
        value: LOCALES[value].id,
        label: t(LOCALES[value].title),
        flag: LOCALES[value].logo,
      });
  }, [LOCALES, value, t]);

  useEffect(() => {
    if (showAllLocales) {
      setOptions(
        Object.keys(LOCALES).map((item) => {
          return {
            value: LOCALES[item].id,
            label: t(LOCALES[item].title),
            flag: LOCALES[item].logo,
          };
        })
      );
      return;
    }
    if (showUserApprovedLocales) {
      let userLocales = userApprovedLocales;
      if (!userLocales) userLocales = [appConstants.DEFAULT_LOCALE];
      setOptions(
        Object.keys(LOCALES)
          .filter((item) => userLocales.includes(item))
          .map((item) => {
            return {
              value: LOCALES[item].id,
              label: t(LOCALES[item].title),
              flag: LOCALES[item].logo,
            };
          })
      );
      return;
    }
    if (coachApprovedLocales) {
      setOptions(
        Object.keys(LOCALES)
          .filter((item) => coachApprovedLocales.includes(item))
          .map((item) => {
            return {
              value: LOCALES[item].id,
              label: t(LOCALES[item].title),
              flag: LOCALES[item].logo,
            };
          })
      );
    }
  }, [
    coachApprovedLocales,
    userApprovedLocales,
    t,
    showAllLocales,
    showUserApprovedLocales,
    LOCALES,
    currentSelectedItem,
  ]);

  const customSelectItem = ({ item, methods }) => (
    <div
      onClick={() => methods.addItem(item)}
      className={classNames(styles.selectItem, {
        [styles.localeSwitchSelectItem]: useLocaleSwitchStyles,
      })}>
      <div className={styles.flagWrapper}>
        <Image
          height={20}
          width={40}
          src={item.flag}
          className={styles.flagImage}
          alt={item?.label}
        />
      </div>
      {item.label}
    </div>
  );

  const customContentRenderer = ({ state }) => {
    const { values } = state || {};
    const [currentValue] = values || [];
    if (!currentValue) return null;

    return (
      <div
        className={classNames(styles.selectedItem, {
          [styles.selectItemLessPadding]: lessPadding,
          [styles.localeSwitchSelectedItem]: useLocaleSwitchStyles,
        })}>
        <div
          className={classNames(styles.flagWrapper, {
            [styles.localeSwitchSelectedItemFlagWrapper]: useLocaleSwitchStyles,
          })}>
          <Image
            height={20}
            width={40}
            src={currentValue.flag}
            className={styles.flagImage}
            alt={currentValue?.label}
          />
        </div>
        <span className={styles.selectLabel}> {currentValue.label}</span>
      </div>
    );
  };

  if (options.length === 0) return null;

  return (
    <>
      {isMobile ? (
        <div
          className={classNames(
            wrapperStyle,
            styles.mobileSelectContainer,
            { [styles.mobileSelectWrappr]: useLocaleSwitchStyles },
            'clickable'
          )}
          onClick={() => {
            if (closeNavBar && typeof closeNavBar === 'function') closeNavBar();
            toggleModal();
          }}>
          <div className={styles.mobileSelectedItem}>
            <div className={styles.selectedItemFlagWrapper}>
              <Image
                height={20}
                width={40}
                src={currentSelectedItem.flag}
                className={styles.flagImage}
                alt={currentSelectedItem?.label}
              />
            </div>
            <span className={styles.selectLabel}>
              {currentSelectedItem.label}
            </span>
          </div>
          <img alt="arrow" src="/static/images/icons/arrowDown.svg" />
        </div>
      ) : (
        <SelectInput
          value={value}
          onChange={onChange}
          disabled={disabled}
          loading={false}
          options={options}
          keepSelectedInList={false}
          itemRenderer={customSelectItem}
          contentRenderer={customContentRenderer}
          hideErrorMessage
          dropdownGap={0}
          className={wrapperStyle}
          {...restProps}
        />
      )}
      {isModalOpen && (
        <SelectLocaleModal
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          modalRef={modalRef}
          onLocaleSelect={onChange}
          localeList={options}
        />
      )}
    </>
  );
};

export default LocaleSelect;
