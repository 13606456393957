import { useImperativeHandle, useState } from 'react';

export const useModal = ({ ref }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen((state) => !state);

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  return { isModalOpen, toggleModal };
};
