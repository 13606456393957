import useShallowEqualSelector from './shallowEqualSelector';

export default function useAuthUser() {
  const {
    user,
    coachDetails,
    coachSchedulingDetails,
    isLoading,
    error,
    authLoading,
  } = useShallowEqualSelector(({ auth }) => ({
    ...auth,
  }));
  return {
    user,
    coachDetails,
    coachSchedulingDetails,
    error,
    isLoading,
    authLoading,
  };
}
