import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { setCookie } from '../utils';
import { getAllLocales, getISOLocale } from '../models/locale';
import useAuthUser from './authUser';
import { setAuthLoading, updateUserProfile } from '../store/slices/auth';
import Analytics from '../services/Analytics';
import useTranslations from './translations';
import { setAppLocale } from '../store/slices/app';

function useLocaleSwitcher() {
  const dispatch = useDispatch();
  const router = useRouter();
  const { user } = useAuthUser();
  const localeList = useMemo(
    () => Object.keys(getAllLocales({ type: 'app' })),
    []
  );
  const { currentLocale } = useTranslations();
  const { pathname, query, asPath, locale } = router;

  const switchLocale = async (nextLocale) => {
    const nextISOLocale = getISOLocale(nextLocale);

    if (currentLocale === nextISOLocale) return;

    if (user) {
      await dispatch(
        updateUserProfile({
          id: user.id,
          profile: {
            locale: nextISOLocale,
          },
          saveToDatabase: true,
        })
      );
    }

    Analytics.setPeopleProperties({ Locale: nextISOLocale });
    Analytics.setSuperProperties({ Locale: nextISOLocale });
    // set preferred locale cookie to expire in an year
    setCookie(
      `PREFERRED_LOCALE=${nextISOLocale}; maxage=${
        1000 * 60 * 60 * 24 * 7
      }; path=/`
    );
    dispatch(setAppLocale(nextISOLocale));
    router
      .replace({ pathname, query }, asPath, {
        locale: nextLocale,
      })
      .finally(() => {
        // If no user is logged in, the auth listener is not fired again on page replace and authLoading state is reset to true
        // As a result the authLoading state is not set to false after locale change
        if (!user) {
          dispatch(setAuthLoading(false));
        }
      });
  };

  return { localeList, switchLocale, currentLocale: locale };
}

export default useLocaleSwitcher;
