import React from 'react';
import Image from 'next/image';
import Modal from 'react-modal';
import { MdClose } from 'react-icons/md';
import styles from './styles.module.scss';
import useTranslations from '../../../../hooks/translations';

Modal.setAppElement('#__next');

const SelectLocaleModal = ({
  isModalOpen,
  toggleModal,
  modalRef,
  onLocaleSelect,
  localeList,
}) => {
  const { t } = useTranslations();

  return (
    <Modal
      ref={modalRef}
      isOpen={isModalOpen}
      onRequestClose={toggleModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.75)',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: 10,
        },
        content: {
          borderRadius: 16,
          position: 'initial',
          padding: 0,
          width: '98%',
          maxHeight: '96%',
        },
      }}>
      <div className={styles.inner}>
        <div>
          <button
            onClick={() => {
              toggleModal();
            }}
            className={styles.closeBtn}>
            <MdClose />
          </button>
        </div>
        <div className={styles.modelContet}>
          <div className={styles.modalTitle}>
            {t('local_select_modal_title')}
          </div>
          <div className={styles.localList}>
            {localeList.map((item) => (
              <div
                key={item.value}
                onClick={() => {
                  onLocaleSelect(item.value);
                  toggleModal();
                }}
                className={`clickable ${styles.localItem}`}>
                <div className={styles.flagWrapper}>
                  <Image
                    height={20}
                    width={40}
                    src={item.flag}
                    className={styles.flagImage}
                    alt={item?.label}
                  />
                </div>
                {item.label}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectLocaleModal;
